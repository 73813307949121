'use strict';

let mediaRecorders = [];
let chunks = [];
let currentDeviceId = "";
let localMediaRecorder;
let api;
customElements.define("jitsi-custom-element",
  class extends HTMLElement {

    constructor() {
      super()
    }

    static get observedAttributes() {
      return ["domain", "room", "displayname", "jwt"]
    }

    connectedCallback() {
      this.init()
    }
    
    init() {
      const domain = this.getAttribute("domain")
      const room = this.getAttribute("room")
      const displayName = this.getAttribute("displayName")
      let options = {}
      console.log("medsyn-jitsi-init");
      if(displayName == "Doctor"){
        options =
        {
          roomName:  room
          , userInfo: {displayName, email:"doctor@medsyn.com.br"}
          , configOverwrite:
            {
              startWithVideoMuted: false
              , startWithAudioMuted: false
              , apiLogLevels: ['error']
              , toolbarButtons:
                ["microphone",
                  "camera",
                  // "fullscreen",
                  "fodeviceselection",
                  // "videoquality",
                  // "filmstrip",
                  // "tileview",
                  "recording",
                  // "shortcuts",
                  // "recording",
                  // "embedmeeting",
                  // "hangup",
                  // "chat",
                  // "livestreaming",
                  // "settings",
                  // "invite",
                  // "feedback",
                  // "download",
                  // "help",
                  // "mute-everyone",
                  // "mute-video-everyone",
                  // "security"
                ]
              , prejoinPageEnabled: false
              , startSilent: false
              , recordings: {
                  recordAudioAndVideo: true,
                  suggestRecording: true,
                  showPrejoinWarning: false
              }
            }
          , interfaceConfigOverwrite: {}
          , parentNode: document.querySelector("jitsi-custom-element")
        }
      } else {
        options =
        {
          roomName:  room
          , userInfo: {displayName}
          , configOverwrite:
            {
              startWithVideoMuted: false
              , startWithAudioMuted: false
              , apiLogLevels: ['error']
              , toolbarButtons:
                ["microphone",
                  "camera",
                  // "fullscreen",
                  "fodeviceselection",
                  // "videoquality",
                  // "filmstrip",
                  // "tileview",
                  // "recording",
                  // "shortcuts",
                  // "recording",
                  // "embedmeeting",
                  // "hangup",
                  // "chat",
                  // "livestreaming",
                  // "settings",
                  // "invite",
                  // "feedback",
                  // "download",
                  // "help",
                  // "mute-everyone",
                  // "mute-video-everyone",
                  // "security"
                ]
              , prejoinPageEnabled: false
              , startSilent: false
              , recordings: {
                  recordAudioAndVideo: true,
                  suggestRecording: true,
                  showPrejoinWarning: false
              }
            }
          , interfaceConfigOverwrite: {}
          , parentNode: document.querySelector("jitsi-custom-element")
        }
      }

      api = new JitsiMeetExternalAPI(domain, options)
      this.api = api
      let jitsiIFrame = document.querySelector("jitsi-custom-element").firstChild;
      jitsiIFrame.setAttribute('loading', 'eager');


      // It calls twice so I had to debounce it
      let debounce = 0
      api.addEventListener("videoConferenceLeft", e => {
        if (debounce == 1) {
          return
        }
        debounce = 1
        this.dispatchEvent(new CustomEvent("videoConferenceLeft"))
        setTimeout(_ => {debounce = 0}, 1000)
      })

      api.addEventListener("participantJoined", e => {
        console.log("medsyn-participantJoined");
        if(this.getAttribute("displayName") != "Doctor") {
          console.log("medsyn-participantJoined");
          api.executeCommand('grantModerator',
              e.Id
          );
        } else {
          console.log("medsyn-doctorReady")
          if(this.getAttribute("displayName") == "Doctor"){
            jitsiIFrame.contentWindow.postMessage("startRecording","https://meet.medsyn.com.br");
          }
  
            api.executeCommand('startRecording', {
              mode: "stream"
          });
        }
        this.dispatchEvent(new CustomEvent("participantJoined", {detail: e}))
      })

      api.addEventListener("videoConferenceJoined", e => {
        let jitsiIFrame = document.querySelector("jitsi-custom-element").firstChild;
        console.log("medsyn-videoconferenceJoined");
        if(this.getAttribute("displayName") == "Doctor"){
          jitsiIFrame.contentWindow.postMessage("startRecording","https://meet.medsyn.com.br");
        }
        this.dispatchEvent(new CustomEvent("videoConferenceJoined", {detail: e}))
      })

      api.addEventListener("participantLeft", e => {
        this.dispatchEvent(new CustomEvent("participantLeft", {detail: e}))
      })

      api.addEventListener("deviceListChanged", e => {
        this.dispatchEvent(new CustomEvent("deviceListChanged", {detail: e}))
      })


      api.addEventListener("videoAvailabilityChanged", e => {
        api.isVideoMuted().then(muted => {
          api.getAvailableDevices().then(devices => {
            app.ports.jitsiVideoAvailable.send(!muted);
          })
        });

        this.dispatchEvent(new CustomEvent("videoAvailabilityChanged", {detail: e}))
      })

      api.addEventListener("audioAvailabilityChanged", e => {
        api.isAudioMuted().then(muted => {
          api.getAvailableDevices().then(devices => {
            app.ports.jitsiAudioAvailable.send(!muted);
          })
        });
        this.dispatchEvent(new CustomEvent("audioAvailabilityChanged", {detail: e}))
      })

      api.addEventListener("recordingStatusChanged", e => {
        app.ports.jitsiIsRecording.send(e.on);
        api.isVideoMuted().then(muted => {
          if(muted){
            api.executeCommand('toggleVideo');
          }
        });
        api.isAudioMuted().then(muted => {
          if(muted){
            api.executeCommand('toggleAudio');
          }
        });
        this.dispatchEvent(new CustomEvent("recordingStatusChanged", {detail: e}))
      })

      api.addEventListener("browserSupport", e => {
        console.log("supported" , e.supported)
        let supported = false;
        if (e.supported) {
          let userAgent = window.navigator.userAgent;
          if(/miui/i.test(userAgent) || /samsungbrowser/i.test(userAgent) ){
            console.log('user agent matched')
            supported = false;
          } else {
            supported = e.supported;
          }
        } else {
          supported = e.supported;
        }
        app.ports.jitsiBrowserSupport.send(supported);
        this.dispatchEvent(new CustomEvent("browserSupport", {detail: e}))
      })

    }
  }
)
