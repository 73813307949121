'use strict';


function lazyLoad(doctorToken) {
  let mmd = document.createElement("script");
  mmd["id"] = "memed-script";
  mmd.type="text/javascript"
  mmd.src = "https://sandbox.memed.com.br/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js";
  mmd.dataset.token="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.WzMyNzgxLCJiMjQ4ZjE1ODRjMmRmNDE2Njg3NzRkNTQ5YWI1Y2U2ZCIsIjIwMjEtMDYtMjkiLCJzaW5hcHNlLnByZXNjcmljYW8iLCJwYXJ0bmVyLjMuMjgxNzkiXQ.oDMSXKRoB44G8zlkQz_P8p6rw1V6x0GHIzzcnOJjgsQ";
  mmd.dataset.color="#576cff";
  mmd.dataset.container="memed-embedded";
  document.body.appendChild(mmd);
  setTimeout(initializeMemed, 1000)
}

function initializeMemed() {

  MdSinapsePrescricao.event.add('core:moduleInit', function moduleInitHandler(module) {

    // O módulo da prescrição foi iniciado
    if (module.name === 'plataforma.prescricao') {
  
    } else {
      console.log('error-memed')
    }
  });
}


function sendMemedHub (params){
  console.log("sendMemedHub", params.externalId, params.name, params.city, params.phone, params.weight, params.height, params.motherName, params.locomotions)

  const cidade = params.city
  const peso = params.weight
  const altura = params.height
  const nome_mae = params.motherName
  const dificuldade_locomocao = params.locomotions

  MdHub.command.send('plataforma.prescricao', 'setFeatureToggle', {
    deletePatient: false,
    removePatient: false,
    editPatient: false
  }).then( function() {
  MdHub.command.send('plataforma.prescricao', 'setPaciente', {
    idExterno: params.externalId,
    nome: params.name,
    cidade,
    telefone: params.phone,
    peso,
    altura,
    nome_mae,
    dificuldade_locomocao
  }).then(function() {
    
    // Mostra o módulo de prescrição
    MdHub.module.show('plataforma.prescricao');
  });})
}

try {
  if (app.ports.lazyLoadMemed) {
      app.ports.lazyLoadMemed.subscribe(lazyLoad);
  }
  if (app.ports.sendMemedHub) {
      app.ports.sendMemedHub.subscribe(sendMemedHub);
  }
} catch (error) {
  console.log(error)
}