/*
Copyright 2019 Raphael Pereira <raphaelpereira@gmail.com>
Copyright 2017 Google Inc.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

'use strict';

import {Howl} from 'howler';

(function SoundManager() {
    let sounds = {};

    let soundCheckAutoPlay = () => {
      return new Promise(resolve => {
        const checkHTML5Audio = () => {
          const audio = new Audio();
          try {
            audio.play();
            resolve(1);
          } catch (err) {
            resolve(-1);
          }
        };
        try {
          const context = Howler.ctx;
          resolve(context.state === 'suspended'?-1:1);
        } catch (e) {
          checkHTML5Audio();
        }
      });
    }

    let soundInit = () => {
        sounds = {
            "beep-short": new Howl({src: ['/assets/sounds/beep-short.mp3']}),
            "beep-long": new Howl({src: ['/assets/sounds/beep-long.mp3']}),
        };
        soundCheckAutoPlay().then(initialized => {
          app.ports.soundIsInitialized.send(initialized);
        }).catch(e => {
          app.ports.soundIsInitialized.send(-1);
        })
    };
    let soundFinish = () => {
        sounds = {};
        app.ports.soundIsInitialized.send(-1);
    };

    let soundIsInitialized = () => {
        app.ports.soundIsInitialized.send(0);
    };

    let currentSound = null;
    let currentPlaying = {};
    let doesNotOverlap = true;
    let soundPlay = sound => {
        if (sounds[sound] == null) {
            return;
        }
        if (sounds[sound] != null) {
            const soundObj = sounds[sound];
            console.assert(soundObj != null, `Unknown sound: ${sound}`);
            if (doesNotOverlap){
              soundObj.play();
              doesNotOverlap = false;
              setTimeout(()=>{ doesNotOverlap = true}, 4000)
            }
            currentSound = sound;
            currentPlaying[sound] = true;
            app.ports.soundIsPlaying.send(sound);
        } else {
            app.ports.soundIsStopped.send(sound);
        }
    };

    let soundPause = sound => {
        if (!currentPlaying[sound]) return;
        if (sounds[sound] != null) {
            const soundObj = sounds[sound];
            console.assert(soundObj != null, `Unknown sound: ${sound}`);
            soundObj.pause();
            app.ports.soundIsPaused.send(sound);
        }
    };

    let soundStop = () => {
        for (let sound of Object.keys(currentPlaying)) {
            if (currentPlaying[sound]) {
                const soundObj = sounds[sound];
                console.assert(soundObj != null, `Unknown sound: ${sound}`);
                soundObj.stop();
            }
        }
        currentPlaying = {};
        app.ports.soundIsStopped.send("");
    };

    if (app.ports.soundInit) {
        app.ports.soundInit.subscribe(soundInit);
    }
    if (app.ports.soundFinish) {
        app.ports.soundFinish.subscribe(soundFinish);
    }
    if (app.ports.soundHasInitialized) {
        app.ports.soundHasInitialized.subscribe(soundIsInitialized);
    }
    if (app.ports.soundPlay) {
        app.ports.soundPlay.subscribe(soundPlay);
    }
    if (app.ports.soundPause) {
        app.ports.soundPause.subscribe(soundPause);
    }
    if (app.ports.soundStop) {
        app.ports.soundStop.subscribe(soundStop);
    }
}());
