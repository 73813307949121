/*
Copyright 2019 Raphael Pereira <raphaelpereira@gmail.com>
Copyright 2017 Google Inc.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

'use strict';

import {PionEvents, PionSession} from './pions-session';
import DetectRTC from 'detectrtc'

window.elmVideoData = {
    devicesList: null,
    localMedia: null,
    session: null,
    quality: null,
    remoteMedia: {}
};

var errorCount = 0;

function handleError(error) {
    app.ports.receiveError.send(error.name);
}

const videoStop = function () {
    let remoteIds = Object.keys(elmVideoData.remoteMedia);
    if (remoteIds.length > 0) {
        for (let i = 0; i < remoteIds.length; i++) {
            removeRemoteVideo(remoteIds[i]);
        }
        elmVideoData.remoteMedia = {};
    }

    if (elmVideoData.session != null) {
        if (elmVideoData.localMedia) {
            elmVideoData.session.removeMedia(elmVideoData.localMedia);
        }
        elmVideoData.session.stop();
        delete elmVideoData.session;
    }

    if (elmVideoData.localMedia) {
        stopAllTracks(elmVideoData.localMedia).then(() => {
            elmVideoData.localMedia = null;
            app.ports.notifyStopped.send(true);
        });
        return;
    }
    app.ports.notifyStopped.send(true);
};

const stopAllTracks = (mediaStream) => {
    const promiseList = mediaStream.getTracks().map(track => {
        new Promise(resolve => {
            track.stop();
            track.onended = function () {
                resolve();
            };
        });
    });
    return Promise.all(promiseList)
};

function videoInit(device) {
    //videoStop();
    const isMobile = ["user", "environment"].includes(device);

    const desktopConstraints = {
        height: {exact: 240},
        width: {exact: 320},
        frameRate: {exact: 15},
        deviceId: device
    };

    const mobileConstraits = {
        facingMode: device,
        //frameRate: {exact: 15},
        // width: {exact: 240},
        // height: {exact: 320},
    };

    const constraints = {
        audio: true,
        video: isMobile ? mobileConstraits : desktopConstraints
    };

    if (elmVideoData.localMedia) {
        elmVideoData
            .localMedia
            .getTracks()
            .forEach(t => {
                console.log("Stopping track: " + t.id);
                t.stop();
            });
        elmVideoData.localMedia = null
    }

    try {
        navigator.mediaDevices.getUserMedia(constraints).then(stream => {
            let streams = {audio: false, video: false};
            elmVideoData.localMedia = stream;
            elmVideoData.localMedia.getTracks().forEach(t => {
                streams[t.kind] = true;
                t.enabled = true
            });

            if (streams['audio'] && streams['video']) {
                app.ports.videoEnabled.send(device);
            } else {
                if (!stream['audio']) alert("Sem audio, recarregue a página.");
                if (!stream['video']) alert("Sem video, recarregue a página.");
                stopAllTracks(stream).then(_ => {
                    if (errorCount < 3) {
                        errorCount++;
                        setTimeout(_ => {
                            videoInit(device);
                        }, 0);
                    } else {
                        errorCount = 0;
                        handleError('NotFoundError');
                    }
                });
            }
        }).catch(err => {
            if (errorCount < 3) {
                errorCount++;
                setTimeout(_ => {
                    videoInit(device);
                }, 0);
            } else {
                errorCount = 0;
                handleError(err);
            }
        });
    } catch (e) {
        app.ports.receiveError.send(e.message);
    }
}

function videoPlay(servers) {
    const videoElement = document.getElementById('localVideo');
    if (videoElement == null) {
        setTimeout(() => {
            videoPlay(servers);
        }, 100);
        return;
    }
    videoElement.srcObject = elmVideoData.localMedia;
    videoElement.muted = true;
    videoElement.volume = 0;
    videoElement.onloadedmetadata = function (e) {
        videoElement.play();
    };

    if (elmVideoData.session == null && servers.signaler) {
        let iceServers = [servers.stun, servers.turn].flat();
        elmVideoData.session = new PionSession(servers.signaler, servers.room, {
            iceServers: iceServers,
            mandatory: {OfferToReceiveVideo: true, OfferToReceiveAudio: true}
        }, 150);

        elmVideoData.session.eventHandler = event => {
            switch (event.type) {
                case PionEvents.WEBSOCKET_OPEN: {
                    console.log("video: connected to signaler")
                }
                case PionEvents.MEDIA_START: {
                    if (event.media == null)
                        break;

                    let id = event.sessionKey;
                    if (id != null && event.media.active) {
                        elmVideoData.remoteMedia[id] = event.media;
                        setTimeout(() => {
                            app.ports.videoRemoteConnected.send(id);
                            console.log("video: media started from " + id)
                        }, 250);
                    }
                    break;
                }
                case PionEvents.MEDIA_STOP: {
                    if (event.media == null)
                        break;

                    let id = event.sessionKey;
                    if (id != null && elmVideoData.remoteMedia[id] != null) {
                        console.log("video: media stopped from " + id);
                        app.ports.videoRemoteDisconnected.send(id);
                    }
                    break;
                }
                case PionEvents.PEER_P2P_MEDIA_STATUS: {
                    let value = event.mediaState;
                    switch (value) {
                        case "new":
                            break;
                        case "checking":
                            break;
                        case "connected":
                            console.log("video:209 status connected: " + event.mediaState);
                            break;
                        case "completed":
                            break;
                        case "disconnected":
                            break;
                        case "failed":
                            break;
                        case "closed":
                            break;
                        default:
                            console.log("video: unhandled media state: " + event.mediaState);
                            value = null;
                            break;
                    }

                    if (value && app.ports.videoRemoteMediaStatus) {
                        console.log("video: media status changed from " + event.sessionKey + ": " + event.mediaState);
                        app.ports.videoRemoteMediaStatus.send({mediaId: event.sessionKey, status: value});
                    }
                    break;
                }
                case PionEvents.PEER_P2P_SIGNALING_STATUS: {
                    console.log("video: signaling status changed from " + event.sessionKey + ": " + event.signalingState);
                    break;
                }
                case PionEvents.PEER_LEAVE_ROOM: {
                    app.ports.videoRemoteMediaStatus.send({mediaId: event.sessionKey, status: "disconnected"});
                    console.log("peer leave room: " + event.sessionKey + ": " + event.signalingState);
                    break;
                }
                default: {
                    let n = (new Date()).toISOString();
                    console.warn(n);
                    console.warn(event);
                }
            }
        };
        elmVideoData.session.start();
        elmVideoData.session.addMedia(elmVideoData.localMedia);
    }
}

function requestAvailableDevices() {
    // const win = window.open("about:blank", "_blank")
    if (!elmVideoData.devicesList) {
        try {
            navigator.mediaDevices.enumerateDevices()
                .then(function (devices) {
                    const device = getDeviceData(devices);
                    elmVideoData.devicesList = device;
                    app.ports.notifyAvailableDevices.send(JSON.stringify(device));
                }).catch(handleError);
        } catch (err) {
            app.ports.notifyAvailableDevices.send(JSON.stringify(getDeviceData()));
        }
    } else {
        app.ports.notifyAvailableDevices.send(JSON.stringify(elmVideoData.devicesList));
    }
}

const getDeviceData = (devices = []) => {
  
  return {
    system: DetectRTC.osName,
    browser: DetectRTC.browser.name,
    version: DetectRTC.browser.version,
    supported: DetectRTC.browser.name.toLowerCase().includes('xiaomi') ? false : DetectRTC.isWebRTCSupported,
    mediaDevices: devices
}};

let videoStatus = mediaId => {
    let f = () => {
        let video = document.getElementById(mediaId);
        if (video == null) {
            return 0;
        }
        if (video.paused) {
            return 1
        }
        return 2
    };

    let resp = f();
    app.ports.videoInformStatus.send({
        'mediaId': mediaId,
        'status': resp
    });
};

let videoRemotePlay = data => {
    try {
        let mediaId = data.id;
        let quality = data.quality;
        let video = document.getElementById(mediaId);
        if (video == null) {
            console.log("video: invalid media " + mediaId);
            return;
        }
        video.play();
        elmVideoData.quality = quality;
        app.ports.videoInformPlaying.send(mediaId);
        console.log("video: remote playing " + mediaId);
    } catch (e) {
        console.log("video: error on videoRemotePlay: " + e.toString());
    }
};

let addRemoteVideo = mediaId => {
    try {
        if (elmVideoData.remoteMedia[mediaId] == null) {
            console.log("video: cannot add remote vídeo, not found " + mediaId);
            return
        }

        let media = elmVideoData.remoteMedia[mediaId];
        if (media == null) {
            console.log("video: invalid media " + mediaId);
            return;
        }

        let video = document.getElementById(mediaId);

        if (video == null) {
            setTimeout(() => {
                addRemoteVideo(mediaId);
            }, 250);
            console.log("video: invalid video element " + mediaId);
            return
        }
        video.srcObject = media;
        video.onloadedmetadata = function (e) {
            app.ports.videoReadyToPlay.send(mediaId);
        };
        console.log("video: remote added " + mediaId);
    } catch (e) {
        console.log("video: error on addRemote: " + e.toString());
    }
};

let removeRemoteVideo = mediaId => {
    if (elmVideoData.remoteMedia[mediaId] == null) {
        return
    }
    delete elmVideoData.remoteMedia[mediaId];
};

let videoTakeSelfie = () => {
    let videoElement = document.getElementById('localVideo');
    let snapshotCanvas = document.getElementById('video-selfie-snap');
    if (videoElement == null || snapshotCanvas == null) {
        console.log("video: failed to get video or snapshot");
        return;
    }

    let helperCanvas = document.createElement('canvas');
    let maxSize = Math.max(snapshotCanvas.offsetWidth, snapshotCanvas.offsetHeight);
    let leftImg = (((videoElement.offsetWidth - maxSize) / 2) / videoElement.offsetWidth) * videoElement.videoWidth;
    let topImg = (((videoElement.offsetHeight - maxSize) / 2) / videoElement.offsetHeight) * videoElement.videoHeight;
    let widthImg = (maxSize / videoElement.offsetWidth) * videoElement.videoWidth;
    let heightImg = (maxSize / videoElement.offsetHeight) * videoElement.videoHeight;
    helperCanvas.width = maxSize;
    helperCanvas.height = maxSize;
    let context = helperCanvas.getContext('2d');
    context.drawImage(videoElement, leftImg, topImg, widthImg, heightImg,
        0, 0, maxSize, maxSize);

    snapshotCanvas.width = snapshotCanvas.offsetWidth;
    snapshotCanvas.height = snapshotCanvas.offsetHeight;
    let leftSnap = (((videoElement.offsetWidth - snapshotCanvas.offsetWidth) / 2) / videoElement.offsetWidth) * videoElement.videoWidth;
    let topSnap = (((videoElement.offsetHeight - snapshotCanvas.offsetHeight) / 2) / videoElement.offsetHeight) * videoElement.videoHeight;
    let widthSnap = (snapshotCanvas.offsetWidth / videoElement.offsetWidth) * videoElement.videoWidth;
    let heightSnap = (snapshotCanvas.offsetHeight / videoElement.offsetHeight) * videoElement.videoHeight;
    context = snapshotCanvas.getContext('2d');
    context.drawImage(videoElement, leftSnap, topSnap, widthSnap, heightSnap,
        0, 0, snapshotCanvas.width, snapshotCanvas.height);

    let dataStr = helperCanvas.toDataURL('image/jpeg', 0.5);
    app.ports.videoSelfieTaken.send(dataStr);
};

let videoDiscardSelfie = () => {
    let videoElement = document.getElementById('localVideo');
    let snapshotCanvas = document.getElementById('video-selfie-snap');
    if (videoElement == null || snapshotCanvas == null) {
        console.log("video: failed to get video or snapshot");
        return;
    }

    let context = snapshotCanvas.getContext('2d');
    context.clearRect(0, 0, snapshotCanvas.width, snapshotCanvas.height);
};

try {
    app.ports.videoInit.subscribe(videoInit);
    app.ports.videoPlay.subscribe(params => {
        setTimeout(_ => {
            videoPlay(params);
        }, 250);
    });
    app.ports.videoStop.subscribe(() => {
        setTimeout(_ => {
            videoStop();
        }, 100)
    });
    app.ports.requestAvailableDevices.subscribe(requestAvailableDevices);
    app.ports.videoRemoteSetup.subscribe(params => {
        setTimeout(_ => {
            addRemoteVideo(params)
        }, 250)
    });
    app.ports.videoRemoteStop.subscribe(params => {
        setTimeout(_ => {
            removeRemoteVideo(params)
        }, 100)
    });
    app.ports.videoRemotePlay.subscribe(videoRemotePlay);
    app.ports.videoStatus.subscribe(videoStatus);
    app.ports.videoTakeSelfie.subscribe(videoTakeSelfie);
    app.ports.videoDiscardSelfie.subscribe(videoDiscardSelfie);
} catch (e) {
    console.log(e);
}
