'use strict';

try {
    let ws = {};
    let latestCid;
    let closeWs = function () {
        if (ws[latestCid] != null) {
            ws[latestCid].close();
            delete ws[latestCid];
        }
    }
    if (app.ports.websocketConnect != null) {
        app.ports.websocketConnect.subscribe(function (info) {
            if (info.host == null || info.cid == null) return;
            if (ws[info.cid]) {
                switch (ws[info.cid].readyState) {
                    case WebSocket.OPEN:
                        setTimeout(_ => {
                            app.ports.websocketConnected.send({});
                        }, 0);
                    // fall through
                    case WebSocket.CONNECTING:
                        return;
                    default:
                        break;
                }
            }

            latestCid = info.cid;
            Object.values(ws).forEach(entry => {
                if (entry != null && entry.close != null) entry.close();
            })
            ws = {
                sentIds : {},
            };

            ws[info.cid] = new WebSocket(info.host);
            ws[info.cid].onopen = function (ev) {
                if (app.ports.websocketConnected != null && latestCid === info.cid) {
                    app.ports.websocketConnected.send({data: ev, timeStamp: ev.timeStamp});
                }
            }
            ws[info.cid].onerror = function (ev) {
                if (app.ports.websocketError != null && latestCid === info.cid) {
                    app.ports.websocketError.send(JSON.stringify(ev));
                }
            }
            ws[info.cid].onclose = function (ev) {
                console.log('[WS] Disconnected on Resgate');
                if (app.ports.websocketDisconnected != null && latestCid === info.cid) {
                    app.ports.websocketDisconnected.send({data: ev, timeStamp: ev.timeStamp});
                }
                delete ws[info.cid];
            }
            ws[info.cid].onmessage = function (msg) {
                if (app.ports.websocketIn != null && latestCid === info.cid) {
                    let m = JSON.parse(msg.data);
                    app.ports.websocketIn.send(msg.data);
                    if (m.id != null) {
                        delete ws.sentIds[m.id];
                    }
                }
            }
        });
    }
    if (app.ports.websocketDisconnect != null) {
        app.ports.websocketDisconnect.subscribe(function () {
            closeWs();
        });
    }
    if (app.ports.websocketSend != null) {
        app.ports.websocketSend.subscribe(function (data) {
            let d = JSON.parse(data);
            if (ws[latestCid] != null) {
                if (d.id != null && ws.sentIds[d.id] == null) {
                    ws[latestCid].send(data);
                    ws.sentIds[d.id] = 1;
                }
            }
        });
    }
} catch (e) {
    console.log(e)
}
