'use strict';


function openDocument(url) {
  console.log('run', url)
  window.open(url, '_blank');
}

try {
  if (app.ports.openDocument) {
      app.ports.openDocument.subscribe(openDocument);
  }
  if (app.ports.openDocumentAsDoctor) {
      app.ports.openDocumentAsDoctor.subscribe(openDocument);
  }
} catch (error) {
  console.log(error)
}