'use strict';

try {
    if (app.ports.sessionSaveToken != null) {
        app.ports.sessionSaveToken.subscribe(function (token) {
            if (token.value) {
                localStorage.setItem('t-' + token.name, JSON.stringify(token));
                if (app.ports.sessionTokenLoaded != null) {
                    app.ports.sessionTokenLoaded.send(token);
                }
            } else {
                localStorage.removeItem('t-'+token.name);
            }
        });
    }
    if (app.ports.sessionLoadToken != null) {
        app.ports.sessionLoadToken.subscribe(function (name) {
            let token = JSON.parse(localStorage.getItem('t-'+name));
            if (app.ports.sessionTokenLoaded != null) {
                if (token != null) {
                    app.ports.sessionTokenLoaded.send(token);
                } else {
                    app.ports.sessionTokenLoaded.send({name: name});
                }
            }
        });
    }
    if (app.ports.sessionClearToken != null) {
        app.ports.sessionClearToken.subscribe(function (token) {
            localStorage.removeItem('t-'+token.name);
        });
    }

} catch(e) {
    console.log(e)
}
