'use strict';


function copyToClipboard(str) {
  console.log(str)
  navigator.clipboard.writeText(str);

}

try {
  if (app.ports.copyStringToClipboard) {
      app.ports.copyStringToClipboard.subscribe(copyToClipboard);
  }
} catch (error) {
  console.log(error)
}