'use strict';

    function initializeDirectCheckout(publicToken, cNumber, hName, secCode, expMonth, expYear){
        return { checkout: new DirectCheckout(publicToken, false),
            cardData:{
                cardNumber: cNumber,
                holderName: hName,
                securityCode: secCode,
                expirationMonth: expMonth,
                expirationYear: expYear
                }
        }
    }

    function generateHash(params) {
        console.log("juno_library:generateHash", params.publicToken, params.cNumber, params.hName, params.secCode, params.expMonth, params.expYear)
        let {checkout, cardData} = initializeDirectCheckout(params.publicToken, params.cNumber, params.hName, params.secCode, params.expMonth, params.expYear)

        checkout.getCardHash(cardData, function(cardHash) {
            /* Sucesso - A variável cardHash conterá o hash do cartão de crédito */
            app.ports.hashReturn.send(cardHash);
        }, function(error) {
            console.log("juno_library:generateHash", error.message)
            /* Erro - A variável error conterá o erro ocorrido ao obter o hash */
            switch (error.message) {
              case "Invalid holder name":
                app.ports.hashReturn.send("Invalid holder name");
                break;
            
              default:
                app.ports.hashReturn.send("");
                break;
            } 
        });
    }

    function validateCard(params){
        console.log("juno_library:validateCard", params.publicToken, params.cNumber, params.hName, params.secCode, params.expMonth, params.expYear)
        let {checkout, cardData} = initializeDirectCheckout(params.publicToken, params.cNumber, params.hName, params.secCode, params.expMonth, params.expYear)


        let number = checkout.isValidCardNumber(cardData.cardNumber);
    
        let code = checkout.isValidSecurityCode(cardData.cardNumber, cardData.securityCode);
    
        let date = checkout.isValidExpireDate(cardData.expirationMonth, cardData.expirationYear);

        if(number !== true){
            console.log('juno_library:validateCard', 'number');
            app.ports.validationStatus.send(false);
            return
        }
        if(code !== true){
          console.log('juno_library:validateCard', 'code');
            app.ports.validationStatus.send(false);
            return
        }
        if(date !== true){
          console.log('juno_library:validateCard', 'date');
            app.ports.validationStatus.send(false);
            return
        }
        // let validation = checkout.isValidCardData(cardData, function(error) {
        //     console.log('juno_library:validateCard', 'isvalid');
        //     console.warn("juno_library:validateCard", error)
        //     app.ports.validationStatus.send(false);
        //     return
        // })
        // if(validation === true){app.ports.validationStatus.send(true)};
        app.ports.validationStatus.send(true)
        return
    }

    function getCardType (params){
        console.log("juno_library:getCardType", params.publicToken, params.cNumber, params.hName, params.secCode, params.expMonth, params.expYear)

        let {checkout, cardData} = initializeDirectCheckout(params.publicToken, params.cNumber, params.hName, params.secCode, params.expMonth, params.expYear)
        let type = checkout.getCardType(cardData.cardNumber);
        if (type === false) { type = ""}
        app.ports.cardType.send(type);
    }

    try {
        if (app.ports.validateCard) {
            app.ports.validateCard.subscribe(validateCard);
        }
        if (app.ports.getCardType) {
            app.ports.getCardType.subscribe(getCardType);
        }
        if (app.ports.generateHash) {
            app.ports.generateHash.subscribe(generateHash);
        }
    } catch (error) {
        console.log(error)
    }