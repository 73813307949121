'use strict';

(function addEventListenerForNexodata(){
  window.addEventListener("message", (event) => {
    const validEvents = ['cancel','excluded','prescricao'];
    if(validEvents.includes(event.data.type)) {
      console.log("Retorno do evento:", event.data.PDFUrl);
      app.ports.nexodataDocumentUrl.send(event.data && event.data.PDFUrl? event.data.PDFUrl: "");
      const modal = document.getElementById("nexodata-modal")
      modal.remove();
    }
  }, false);
})();


function initModal (token){
  console.log("nexodata:initModal", token)

  const ref = token.split(":")[0]
  const auth = token.split(":")[1]
  
  const modal = document.createElement("div")
  modal.id = "nexodata-modal"
  const nexodataIframe = document.createElement("iframe")
  nexodataIframe.src = "https://receitas-staging.nexodata.com.br/prescription/" + ref + "/" + auth
  nexodataIframe.className = "nexodata-content"
  modal.appendChild(nexodataIframe)
  document.body.appendChild(modal)

  // When the user clicks anywhere outside of the modal, close it
  window.onclick = function(event) {
    if (event.target == modal) {
      modal.style.display = "none";
    }
  }
}

try {
  if (app.ports.initializeNexodataModal) {
      app.ports.initializeNexodataModal.subscribe(initModal);
  }
} catch (error) {
  console.log(error)
}